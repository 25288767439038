* {
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 300;
}

/* styles.css */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

body {
    display: flex;
    /* height: 100%; */
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
    /* background-image: linear-gradient(to bottom,); */
    background: linear-gradient(172.78deg, #FFFFFF -4.29%, #F6F4F5 8.5%, #F3EFF5 28.82%, #EEEFF7 52.91%, #E4F0F8 85.27%, #EAF0F7 115.69%);

}

.red_bg{
 width: 100%;
 /* max-width: 100vh; */
 height: 30%;
 /* background-image: url("red_bg.png"); */
 display: flex;
 flex-direction: column;
 align-items: center;
 position: absolute;
 left: 0;
 right: 0;
 top: 0;
}

.main_card_content{
    width: 300px;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    /* background-color: red; */
    /* align-items: center; */
}

.bg_design{
    height: 111%;
    width: 100%;
    /* background-image: url("card_bg.png"); */
    position: absolute;
    z-index: 1;

}
.get_your_card{
    position: relative;
    z-index: 2;
    margin-top: 10px;
    border-radius: 40px;
    border: 1px solid #fff;
    box-sizing: border-box;
    padding: 6.5px 8px;
    font-size: 10px;
    color: #fff;
    font-family: Almarai;
}

.card_container {
    width: 100%;
    height: 75%;
    text-align: center;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card{
    /* height: 200px; */
    width: 100%;
    /* height: 55%; */
    scale: 0.8;
    /* align-items: start; */
}

.card_name{
    position: absolute;
    z-index: 3;
    bottom: 28%;
    left: 15%;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
}

.card_desig{
    position: absolute;
    z-index: 3;
    bottom: 22%;
    left: 15%;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;
}

.initial_container{
    width: 100%;display: flex;flex-direction: row;justify-items: center;align-content: center;align-items: center;justify-content: center;
}
.initial_circle{
    position: absolute;
    height: 60px;
    width:60px;
    z-index: 2;
    bottom:-14%;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
    color: #A29797;
    align-items: center;
    justify-items: center;
    border-radius: 50px;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.main_content{
    margin-top: 20%;
    width: 300px;
    text-align: center;

}

.text{
    font-family: "Almarai";
    font-weight: 400;
    
    color: black;
}

.user_name{
    font-size: 20px;
    padding: 1px 0;
}

.user_desg{
    font-size: 12px;
    padding: 5px 0;
}

.user_company{
    font-size: 12px;
    padding: 1px 0;
}

.save_contact {
    border-radius: 100px;
    background-color: #0071ff;
    height: 15%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 7.5% 5%;
    padding: 2.5%;
    box-sizing: border-box;
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-family: Almarai;
}


.headings {
    width: 100%;
    position: relative;
    font-size: 16px;
    font-family: Almarai;
    color: #000;
    text-align: center;
    display: inline-block;
    height: 16px;
}

.about_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 5%;
}
.about_content{
    width: 100%;
    position: relative;
    font-size: 14px;
    font-weight: 300;
    font-family: Almarai;
    color: #000;
    text-align: justify;
    display: inline-block;
    padding: 5% 0%;
    line-height: 1.5em;
}

.connect_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 5% 5%;
}


.connect_option_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    padding: 5% 0%;
}



.connect_option {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background-color: #fbfbfb;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    margin: 0 2%;
    box-sizing: border-box;
    text-align: center;
    font-size: 8px;
    color: #000;
    font-family: Almarai;
}

.connect_icon{
    width: 25px;
    /* height: 15px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.connect_text{
    padding-top: 10px;
    font-family: Almarai;
    font-size: 8px;
    font-weight: 300;
    line-height: 8.93px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}



.socials_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 0% 5%;
}

.socials_options_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    padding: 10% 0%;
}

.socials{
    height: 2.5em;
    width: 2.5em;
}

.contact_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 0% 5%;
}

.contact_options_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0%;
}

.contact{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: center;
    justify-items: center;
    padding: 2% 0%;
    text-align: start;
    overflow: auto;
}

.contact_icon{
    width: 1.5em;
    height:  1.5em;
    /* height: 15px; */
    padding-right: 10px;
    align-self: center;
}

.contact_text{
    width: 100%;
    font-family: Almarai;
    font-size: 15px;
    font-weight: 400;
    line-height: 16.74px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    overflow: hidden;
}



@media (min-width:320px)  { 
    .red_bg{
        height: 37%;
    }
    .main_card_content{
        width: 100%;
        height: 124.5%;
    }
    .get_your_card{
        font-size: 12px;
    }
    .card_container{
        margin-top: 1%;
    }
    .card_name{
        bottom: 27%;
        left: 16%;
        font-size: 12px;
    }
    .card_desig{
        bottom: 22%;
        left: 16%;
        font-size: 10px;
    }
    .initial_circle{
        height: 4.5em;
        width: 4.5em;
        border-radius: 4.5em;
    }
    .main_content{
        margin-top: 100%;
        width: 95%;
    }
    .save_contact{
        padding: 4% 0;
    }
}


@media (min-width:375px)  { 
    .red_bg{
        height: 39%;
    }
    .main_card_content{
        width: 100%;
        height: 130%;
    }
    .get_your_card{
        font-size: 12px;
    }
    .card_container{
        scale: 0.95;
    }
    .card_name{
        bottom: 29%;
        left: 16%;
        font-size: 12px;
    }
    .card_desig{
        bottom: 24%;
        left: 16%;
        font-size: 10px;
    }
    .main_content{
        margin-top: 100%;
        width: 95%;
    }
    .save_contact{
        padding: 4% 0;
    }
}

@media (min-width:425px)  { 
    .red_bg{
        height: 40%;
    }
    .main_card_content{
        width: 100%;
        height: 134%;
    }
    .get_your_card{
        font-size: 12px;
    }
    .card_container{
       
        scale: 0.85;
    }
    .card_name{
        bottom: 20%;
        font-size: 16px;
    }
    .card_desig{
        bottom: 10%;
        font-size: 14px;
    }
    .initial_circle{
        height: 5em;
        width: 5em;
        border-radius: 5em;
    }
    .main_content{
        margin-top: 85%;
        width: 80%;
    }
}


/* 
@media (max-width:425px)  { 
    .red_bg{
        height: 40%;
    }
    .main_card_content{
        width: 100%;
        height: 134%;
    }
    .get_your_card{
        font-size: 12px;
    }
    .card_container{
       
        scale: 0.85;
    }
    .card_name{
        bottom: 20%;
        font-size: 16px;
    }
    .card_desig{
        bottom: 10%;
        font-size: 14px;
    }
    .initial_circle{
        height: 5em;
        width: 5em;
        border-radius: 5em;
    }
    .main_content{
        margin-top: 85%;
        width: 80%;
    }
}
 */


@media (min-width:426px){
    .red_bg{
        width: 100%;
        /* max-width: 100vh; */
        height: 30%;
        /* background-image: url("red_bg.png"); */
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
    }
   
    .main_card_content{
        width: 300px;
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        /* background-color: red; */
        /* align-items: center; */
    }

    .get_your_card{
        position: relative;
        z-index: 2;
        margin-top: 10px;
        border-radius: 40px;
        border: 1px solid #fff;
        box-sizing: border-box;
        padding: 6.5px 8px;
        font-size: 10px;
        color: #fff;
        font-family: Almarai;
    }
        
    .card_container {
        width: 100%;
        height: 75%;
        text-align: center;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
   
.card{
    /* height: 200px; */
    width: 100%;
    /* height: 55%; */
    scale: 0.8;
    /* align-items: start; */
}

.card_name{
    position: absolute;
    z-index: 3;
    bottom: 25%;
    left: 15%;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
}

.card_desig{
    position: absolute;
    z-index: 3;
    bottom: 18%;
    left: 15%;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 10px;
    color: #FFFFFF;
}

.initial_circle{
    position: absolute;
    height: 4em;
    width:4em;
    z-index: 2;
    bottom:-14%;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 16px;
    color: #A29797;
    align-items: center;
    justify-items: center;
    border-radius: 50px;
    background-color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.main_content{
    margin-top: 100%;
    width: 300px;
    text-align: center;

}

}




    




